import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { APP_URL } from "../../constant/constant";

export const MobileMain = (): JSX.Element => {
  const screenWidth = useWindowWidth();

  return (
    <div className="w-full flex flex-row justify-center bg-[#ffffff]">
      <div
        className={`bg-[#ffffff] relative ${
          screenWidth < 1000
            ? "w-full"
            : screenWidth >= 1000
            ? "w-[1440px]"
            : ""
        } ${
          screenWidth < 1000
            ? "h-[8685px]"
            : screenWidth >= 1000
            ? "h-[5984px]"
            : ""
        } ${screenWidth < 1000 ? "overflow-hidden" : ""}`}
        data-collection-1-mode={
          screenWidth < 1000
            ? "mobile"
            : screenWidth >= 1000
            ? "desktop"
            : undefined
        }
      >
        {screenWidth >= 1000 && (
          <>
            <div className="flex flex-col w-[1440px] items-start gap-2.5 pt-8 pb-[18px] px-28 absolute top-0 left-0 bg-[#ffffff]">
              <div className="h-16 flex items-start relative self-stretch w-full">
                <div className="flex flex-col items-start gap-[6.54px] relative flex-1 grow">
                  <img
                    className="relative w-[184px] h-[46px]"
                    alt="Condoc logo"
                    src="/img/condoc-logo-1-2.png"
                  />
                </div>
                <div className="inline-flex items-center gap-10 relative self-stretch flex-[0_0_auto]">
                  <div className="inline-flex items-end justify-center gap-10 pt-2 pb-0 px-0 self-stretch relative flex-[0_0_auto]"></div>
                  <a
                    className="all-[unset] cursor-pointer box-border inline-flex items-center gap-4 relative flex-[0_0_auto]"
                    href={APP_URL}
                  >
                    <div className="inline-flex items-center justify-center gap-2.5 pt-4 pb-3 px-6 relative flex-[0_0_auto] bg-[#fcc536] rounded-[100px]">
                      <div className="mt-[-1.00px] font-[number:var(--thai-subtitle2-regular-font-weight)] text-neutral-800 text-[length:var(--thai-subtitle2-regular-font-size)] leading-[var(--thai-subtitle2-regular-line-height)] relative w-fit font-thai-subtitle2-regular tracking-[var(--thai-subtitle2-regular-letter-spacing)] whitespace-nowrap [font-style:var(--thai-subtitle2-regular-font-style)]">
                        ทดลองใช้ฟรี
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[1440px] items-center gap-8 px-28 py-8 absolute top-[134px] left-0">
              <div className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start justify-center gap-6 relative flex-1 self-stretch grow">
                  <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-[314px] h-[84px]"
                      alt="Condoc logo"
                      src="/img/condoc-logo-2-1.png"
                    />
                    <div className="self-stretch font-[number:var(--thai-h5-regular-font-weight)] text-black text-[length:var(--thai-h5-regular-font-size)] leading-[var(--thai-h5-regular-line-height)] relative font-thai-h5-regular tracking-[var(--thai-h5-regular-letter-spacing)] [font-style:var(--thai-h5-regular-font-style)]">
                      AI ที่ช่วยจัดการงานเอกสาร
                      <br />
                      ที่เคยใช้เวลาหลายวัน ให้เหลือไม่กี่นาที
                    </div>
                  </div>
                  <a
                    className="bg-[#fcc536] cursor-pointer inline-flex items-center justify-center gap-2.5 pt-5 pb-4 px-8 relative flex-[0_0_auto] rounded-[100px]"
                    href={APP_URL}
                  >
                    <div className="mt-[-1.00px] font-[number:var(--thai-subtitle2-regular-font-weight)] text-neutral-800 text-[length:var(--thai-subtitle2-regular-font-size)] leading-[var(--thai-subtitle2-regular-line-height)] relative w-fit font-thai-subtitle2-regular tracking-[var(--thai-subtitle2-regular-letter-spacing)] whitespace-nowrap [font-style:var(--thai-subtitle2-regular-font-style)]">
                      ทดลองใช้ฟรี
                    </div>
                  </a>
                </div>
                <img
                  className="relative w-[483px] h-[325px]"
                  alt="Meeting"
                  src="/img/meeting-1-1.svg"
                />
              </div>
            </div>
            <div className="flex flex-col w-[1440px] items-start gap-12 pt-[60px] pb-8 px-28 absolute top-[963px] left-0 rounded-[30px]">
              <div className="w-[280px] top-[105px] left-28 absolute h-[19px] bg-[#fcc536]" />
              <div className="justify-center gap-[118px] flex items-center relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col w-[308px] items-start gap-3 relative self-stretch">
                  <div className="justify-center flex-[0_0_auto] flex flex-col w-[280px] items-start relative">
                    <div className="relative w-fit mt-[-1.00px] font-thai-h2-medium font-[number:var(--thai-h2-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h2-medium-font-size)] tracking-[var(--thai-h2-medium-letter-spacing)] leading-[var(--thai-h2-medium-line-height)] whitespace-nowrap [font-style:var(--thai-h2-medium-font-style)]">
                      คุณสมบัติเด่น
                    </div>
                    <div className="font-[number:var(--thai-h5-medium-font-weight)] text-[#595959] text-[length:var(--thai-h5-medium-font-size)] leading-[var(--thai-h5-medium-line-height)] whitespace-nowrap relative w-fit font-thai-h5-medium tracking-[var(--thai-h5-medium-letter-spacing)] [font-style:var(--thai-h5-medium-font-style)]">
                      ของ ConDoc.ai
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-end gap-12 relative flex-1 self-stretch grow">
                  <div className="inline-flex items-center gap-8 relative self-stretch flex-[0_0_auto]">
                    <div className="w-40 self-stretch flex flex-col items-start gap-6 relative">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-635-4.svg"
                      />
                      <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle1-medium-font-weight)] text-black text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          ดึงข้อมูลด้วย 
                          <br />
                          AI อัจฉริยะ
                        </div>
                        <div className="relative self-stretch font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          เป็นมากกว่าแค่ OCR
                          <br />
                          แต่อ่าน และหาข้อมูล 
                          <br />
                          ที่ต้องการในเอกสาร
                        </div>
                      </div>
                    </div>
                    <div className="w-40 self-stretch flex flex-col items-start gap-6 relative">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-636-2.svg"
                      />
                      <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-black text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          ประมวลผลได้
                          <br />
                          อย่างรวดเร็ว
                        </div>
                        <div className="relative self-stretch font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          จัดการเอกสารเสร็จ
                          <br />
                          ในไม่กี่วินาที ไม่ต้อง
                          <br />
                          เสียเวลาหลายชั่วโมง
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-[180px] items-start gap-6 relative self-stretch">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-637-2.svg"
                      />
                      <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-black text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          รองรับการจัดการ
                          <br />
                          เอกสารจำนวนมาก
                        </div>
                        <div className="self-stretch font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] leading-[var(--thai-body-regular-line-height)] relative font-thai-body-regular tracking-[var(--thai-body-regular-letter-spacing)] [font-style:var(--thai-body-regular-font-style)]">
                          AI ที่จัดการงานเอกสาร
                          <br />
                          กองโตด้วยตัวเองแล้ว
                          <br />
                          แจ้งคุณเมื่อทำงานเสร็จ
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-6 flex-1 grow flex-col items-start relative">
                    <img
                      className="relative flex-[0_0_auto]"
                      alt="Frame"
                      src="/img/frame-637-3.svg"
                    />
                    <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-black text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        รองรับเอกสาร
                        <br />
                        หลายประเภท
                      </div>
                      <div className="relative self-stretch font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                        รองรับเอกสารหลาย
                        <br />
                        ฟอร์แมท แม้เป็นงาน
                        <br />
                        ประเภทเดียวกัน
                        <br />
                        หลายรูปแบบ
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[1440px] items-start gap-14 pt-20 pb-14 px-28 absolute top-[1367px] left-0 bg-[#f7f9ff]">
              <div className="flex w-[1216px] items-start gap-[88px] pl-14 pr-0 py-0 relative flex-[0_0_auto]">
                <img
                  className="relative w-[172.9px] h-[205px]"
                  alt="Table"
                  src="/img/table-1.svg"
                />
                <p className="relative flex-1 self-stretch mt-[-1.00px] font-thai-h2-medium font-[number:var(--thai-h2-medium-font-weight)] text-black text-[length:var(--thai-h2-medium-font-size)] tracking-[var(--thai-h2-medium-letter-spacing)] leading-[var(--thai-h2-medium-line-height)] [font-style:var(--thai-h2-medium-font-style)]">
                  เปลี่ยนงานเอกสารที่ซ้ำซาก จำเจ ของคน
                  <br />
                  ให้เป็นเรื่องง่ายเพียงไม่กี่คลิก ด้วย AI
                </p>
              </div>
              <div className="items-start gap-8 self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                  <img
                    className="relative w-[352px] h-[239px] object-cover"
                    alt="Image"
                    src="/img/image-1-1.png"
                  />
                  <div className="gap-2.5 pl-12 pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                    <div className="relative flex-1 mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                      ดึงเฉพาะข้อมูล ส่วนที่ต้องการ
                      <br />
                      ของเอกสารต่างๆ
                    </div>
                  </div>
                </div>
                <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                  <img
                    className="relative w-[274.85px] h-60 object-cover"
                    alt="Image"
                    src="/img/image-2-1.png"
                  />
                  <div className="gap-2.5 pl-12 pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                    <div className="relative flex-1 mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                      ให้มาอยู่เป็นระเบียบ ในรูปแบบ
                      <br />
                      ตาราง พร้อมกันได้ในทีเดียว
                    </div>
                  </div>
                </div>
                <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                  <img
                    className="relative w-[290.03px] h-60 object-cover"
                    alt="Image"
                    src="/img/image-3-1.png"
                  />
                  <div className="gap-2.5 pl-12 pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                    <div className="relative flex-1 mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                      หรือนำมาใช้ต่อในอีเมล์, รายงาน,
                      <br />
                      ฟอร์ม, เทมเพลตต่างๆ ได้เลย
                    </div>
                  </div>
                </div>
                <img
                  className="absolute w-[73px] h-11 top-[105px] left-[780px] object-cover"
                  alt="Image"
                  src="/img/image-6.png"
                />
                <img
                  className="absolute w-[73px] h-11 top-[105px] left-[380px] object-cover"
                  alt="Image"
                  src="/img/image-6.png"
                />
              </div>
            </div>
          </>
        )}

        <div
          className={`left-0 absolute ${
            screenWidth >= 1000
              ? "w-[1440px]"
              : screenWidth < 1000
              ? "w-full"
              : ""
          } ${
            screenWidth >= 1000
              ? "top-[2096px]"
              : screenWidth < 1000
              ? "top-[7525px]"
              : ""
          } ${
            screenWidth >= 1000
              ? "h-[1475px]"
              : screenWidth < 1000
              ? "h-[1272px]"
              : ""
          }`}
        >
          {screenWidth >= 1000 && (
            <>
              <div className="flex flex-col w-[1440px] items-start gap-14 px-28 py-20 absolute top-0 left-0 bg-[#fef1d6]">
                <div className="flex flex-col items-start gap-14 pt-0 pb-12 px-0 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#8c8c8c]">
                  <div className="w-[1216px] items-start gap-6 pl-14 pr-0 py-0 flex-[0_0_auto] flex relative">
                    <p className="relative self-stretch w-[518px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-transparent text-6xl tracking-[0] leading-[60px]">
                      <span className="font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] leading-[var(--thai-h1-semibold-line-height)] font-thai-h1-semibold [font-style:var(--thai-h1-semibold-font-style)] tracking-[var(--thai-h1-semibold-letter-spacing)] text-[length:var(--thai-h1-semibold-font-size)]">
                        Extract
                        <br />
                      </span>
                      <span className="font-[number:var(--thai-h2-medium-font-weight)] text-black text-[length:var(--thai-h2-medium-font-size)] leading-[var(--thai-h2-medium-line-height)] font-thai-h2-medium [font-style:var(--thai-h2-medium-font-style)] tracking-[var(--thai-h2-medium-letter-spacing)]">
                        อ่าน - ดึง ข้อมูลจากเอกสาร
                      </span>
                    </p>
                    <img
                      className="relative w-[351.66px] h-[205px]"
                      alt="Frame"
                      src="/img/frame.svg"
                    />
                  </div>
                  <div className="items-start gap-8 self-stretch w-full flex-[0_0_auto] flex relative">
                    <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                      <div className="inline-flex items-end justify-center gap-[12.6px] relative flex-[0_0_auto]">
                        <img
                          className="relative w-[178.34px] h-[244.14px]"
                          alt="Group"
                          src="/img/group-2188.png"
                        />
                        <img
                          className="relative w-[307.63px] mb-[-0.29px] mr-[-0.29px]"
                          alt="Frame"
                          src="/img/frame-48095574.png"
                        />
                        <img
                          className="absolute w-[202px] h-[26px] top-[70px] left-[171px]"
                          alt="Vector"
                          src="/img/vector-1.svg"
                        />
                        <img
                          className="absolute w-[246px] h-[26px] top-[78px] left-[50px]"
                          alt="Vector"
                          src="/img/vector-2.svg"
                        />
                        <img
                          className="absolute w-[95px] h-[66px] top-[88px] left-[148px]"
                          alt="Vector"
                          src="/img/vector-3.svg"
                        />
                      </div>
                      <div className="gap-6 pl-[57px] pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-thai-h1-semibold font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h1-semibold-font-size)] tracking-[var(--thai-h1-semibold-letter-spacing)] leading-[var(--thai-h1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-h1-semibold-font-style)]">
                          01
                        </div>
                        <p className="flex-1 font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          AI ที่สามารถอ่าน และเข้าใจว่า อยากได้ข้อมูลอะไรมาใส่ใน
                          Excel ให้เราได้
                        </p>
                      </div>
                      <div className="absolute w-[86px] h-4 top-6 left-[132px] border border-solid border-[#667fbf]" />
                      <div className="absolute w-[66px] h-4 top-[59px] left-[152px] border border-solid border-[#667fbf]" />
                      <div className="absolute w-12 h-4 top-[152px] left-[170px] border border-solid border-[#667fbf]" />
                      <div className="absolute w-[82px] h-4 top-[103px] left-14 border border-solid border-[#667fbf]" />
                      <img
                        className="absolute w-[276px] h-16 top-8 left-[218px]"
                        alt="Vector"
                        src="/img/vector-1-1.svg"
                      />
                    </div>
                    <div className="flex flex-col items-end gap-5 relative flex-1 self-stretch grow">
                      <div className="inline-flex h-[244.14px] items-end justify-center pl-0 pr-9 py-0 gap-[12.6px] relative">
                        <img
                          className="ml-[-0.29px] relative w-[307.63px] mb-[-0.29px] mr-[-0.29px]"
                          alt="Frame"
                          src="/img/frame-48095574-1.png"
                        />
                        <div className="flex flex-col w-7 items-start absolute top-[94px] left-[-7px] shadow-drop-shadow-0-12-0-8-0-5">
                          <div className="flex items-center gap-[3.53px] pt-[5.88px] pb-[6.17px] px-[5.88px] relative self-stretch w-full flex-[0_0_auto] bg-[#ffffff] border-r-[0.29px] [border-right-style:solid] border-[#d9d9d9] shadow-[inset_0px_-0.29px_0px_#e1e1e1]">
                            <div className="flex flex-col items-center justify-center gap-[2.94px] relative flex-1 grow">
                              <div className="relative w-fit mt-[-0.29px] [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                                1
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-[3.53px] pt-[5.88px] pb-[6.17px] px-[5.88px] relative self-stretch w-full flex-[0_0_auto] bg-[#ffffff] border-r-[0.29px] [border-right-style:solid] border-[#d9d9d9] shadow-[inset_0px_-0.29px_0px_#e1e1e1]">
                            <div className="flex flex-col items-center justify-center gap-[2.94px] relative flex-1 grow">
                              <div className="relative w-fit mt-[-0.29px] [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                                2
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-[3.53px] pt-[5.88px] pb-[6.17px] px-[5.88px] relative self-stretch w-full flex-[0_0_auto] bg-white border-r-[0.29px] [border-right-style:solid] border-[#d9d9d9] shadow-[inset_0px_-0.29px_0px_#e1e1e1]">
                            <div className="flex flex-col items-center justify-center gap-[2.94px] relative flex-1 grow">
                              <div className="relative w-fit mt-[-0.29px] [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                                3
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="absolute w-[119px] h-[163px] top-[13px] left-[107px]"
                        alt="Group"
                        src="/img/group-2189.png"
                      />
                      <div className="flex items-center justify-center gap-6 pl-[104px] pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-thai-h1-semibold font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h1-semibold-font-size)] tracking-[var(--thai-h1-semibold-letter-spacing)] leading-[var(--thai-h1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-h1-semibold-font-style)]">
                          02
                        </div>
                        <p className="relative flex-1 font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          โยนเป็น 10 ไฟล์ 100 ไฟล์ ให้ AI
                          <br />
                          ทำให้ไฟล์ละแถวได้เลย
                        </p>
                      </div>
                      <img
                        className="absolute w-[119px] h-[163px] top-[39px] left-[88px]"
                        alt="Group"
                        src="/img/group-2189.png"
                      />
                      <div className="absolute w-[121px] h-[163px] top-[67px] left-16">
                        <div className="relative w-[119px] h-[163px] bg-[#ffffff] border-[0.29px] border-solid border-[#bfbfbf]">
                          <div className="absolute top-[7px] left-2.5 [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                            1
                          </div>
                          <div className="absolute w-[50px] h-1 top-[19px] left-[60px] bg-[#d9d9d9] rounded-[1.15px]" />
                          <div className="absolute w-[62px] h-1 top-[42px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[38px] h-1 top-[42px] left-[72px] bg-[#d9f7be] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[97px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-16 left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[119px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[57px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-28 left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[79px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[134px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[149px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[95px] h-1 top-[35px] left-[15px] bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[95px] h-1 top-[90px] left-[15px] bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[50px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[74px] h-1 top-[105px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[26px] h-1 top-[105px] left-[84px] bg-[#bae7ff] rounded-[1.15px]" />
                          <div className="absolute w-[50px] h-1 top-[72px] left-2 bg-[#fff1b8] rounded-[1.15px]" />
                          <div className="absolute w-[50px] h-1 top-[72px] left-[60px] bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[127px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[102px] h-1 top-[141px] left-2 bg-[#f0f0f0] rounded-[1.15px]" />
                          <div className="absolute w-[35px] h-1 top-3 left-[76px] bg-[#f0f0f0] rounded-[1.15px]" />
                        </div>
                      </div>
                      <div className="absolute top-[46px] left-[98px] [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                        2
                      </div>
                      <div className="absolute top-[19px] left-[118px] [font-family:'Inter',Helvetica] font-normal text-grey-4 text-[15.4px] tracking-[0] leading-[normal]">
                        3
                      </div>
                    </div>
                    <img
                      className="top-32 left-[576px] absolute w-20 h-12"
                      alt="Arrow"
                      src="/img/arrow.svg"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-[57px] relative self-stretch w-full flex-[0_0_auto]">
                  <div className="w-[1216px] items-start gap-6 pl-14 pr-0 py-0 flex-[0_0_auto] flex relative">
                    <p className="relative self-stretch w-[518px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-transparent text-6xl tracking-[0] leading-[60px]">
                      <span className="font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] leading-[var(--thai-h1-semibold-line-height)] font-thai-h1-semibold [font-style:var(--thai-h1-semibold-font-style)] tracking-[var(--thai-h1-semibold-letter-spacing)] text-[length:var(--thai-h1-semibold-font-size)]">
                        Fill
                        <br />
                      </span>
                      <span className="font-[number:var(--thai-h2-medium-font-weight)] text-black text-[length:var(--thai-h2-medium-font-size)] leading-[var(--thai-h2-medium-line-height)] font-thai-h2-medium [font-style:var(--thai-h2-medium-font-style)] tracking-[var(--thai-h2-medium-letter-spacing)]">
                        รวม - กรอก ลงฟอร์มต่างๆ
                        <br />
                      </span>
                      <span className="text-[#434343] text-[length:var(--thai-h5-regular-font-size)] leading-[var(--thai-h5-regular-line-height)] font-thai-h5-regular [font-style:var(--thai-h5-regular-font-style)] font-[number:var(--thai-h5-regular-font-weight)] tracking-[var(--thai-h5-regular-letter-spacing)]">
                        สำหรับธุรกิจที่ต้องนำข้อมูลไปกรอกลงรายงาน หรือฟอร์มมากๆ
                      </span>
                    </p>
                    <img
                      className="relative w-[284.41px] h-[233px] object-cover"
                      alt="Image"
                      src="/img/image-7.png"
                    />
                  </div>
                  <div className="items-start gap-8 self-stretch w-full flex-[0_0_auto] flex relative">
                    <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                      <div className="inline-flex items-start justify-center flex-[0_0_auto] gap-[12.6px] relative">
                        <img
                          className="relative w-[307.63px] mt-[-0.29px] mb-[-0.29px] ml-[-0.29px]"
                          alt="Frame"
                          src="/img/frame-48095574-2.png"
                        />
                        <div className="relative w-[214.09px] h-[133.6px] mr-[-2.00px]">
                          <div className="relative w-[212px] h-[134px] bg-[#ffffff] border-[0.22px] border-solid border-[#bfbfbf]">
                            <div className="absolute w-[117px] h-[5px] top-[53px] left-[84px] bg-[#f0f0f0] rounded-[0.88px] rotate-180" />
                            <div className="absolute w-16 h-[5px] top-[22px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-14 h-[5px] top-3.5 left-[18px] bg-[#d9d9d9] rounded-[0.88px]" />
                            <div className="absolute w-[72px] h-[5px] top-[53px] left-2.5 bg-[#d9f7be] rounded-[0.88px] rotate-180" />
                            <div className="absolute w-[125px] h-[5px] top-[37px] left-[18px] bg-[#bae7ff] rounded-[0.88px]" />
                            <div className="absolute w-[55px] h-[5px] top-[37px] left-[146px] bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[108px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[59px] h-[5px] top-[70px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[51px] h-[5px] top-[70px] left-[150px] bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[62px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[87px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[179px] h-[5px] top-[100px] left-[22px] bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[45px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[77px] h-[5px] top-[70px] left-[71px] bg-[#fff1b8] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[79px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-[191px] h-[5px] top-[116px] left-2.5 bg-[#f0f0f0] rounded-[0.88px]" />
                            <div className="absolute w-7 h-[11px] top-[11px] left-[173px] bg-[#c81f1f]">
                              <div className="top-px left-1.5 text-[8.2px] absolute [font-family:'Inter',Helvetica] font-semibold text-[#ffffff] tracking-[0] leading-[normal]">
                                PDF
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gap-6 pl-[39px] pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-thai-h1-semibold font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h1-semibold-font-size)] tracking-[var(--thai-h1-semibold-letter-spacing)] leading-[var(--thai-h1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-h1-semibold-font-style)]">
                          03
                        </div>
                        <div className="flex-1 font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          AI นำข้อมูลจากตาราง มากรอกลงแบบฟอร์ม
                          <br />
                          ที่ต้องการ (หรือมีข้อมูลเฉพาะเพิ่มเติมก็ได้)
                        </div>
                      </div>
                    </div>
                    <div className="flex-col items-center gap-5 flex-1 self-stretch grow flex relative">
                      <div className="flex items-center pl-[60px] pr-12 py-0 self-stretch w-full flex-[0_0_auto] gap-[12.6px] relative">
                        <img
                          className="relative w-[307.63px] mt-[-0.29px] mb-[-0.29px] ml-[-0.29px]"
                          alt="Frame"
                          src="/img/frame-48095574-3.png"
                        />
                        <div className="absolute w-[120px] h-[74px] top-0 left-[399px]">
                          <div className="relative w-[118px] h-[74px] bg-[#ffffff] border-[0.12px] border-solid border-[#8c8c8c]">
                            <div className="absolute w-[65px] h-[3px] top-[30px] left-[46px] bg-[#f0f0f0] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-9 h-[3px] top-3 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-2 left-2.5 bg-[#d9d9d9] rounded-[0.49px]" />
                            <div className="absolute w-10 h-[3px] top-[30px] left-[5px] bg-[#d9f7be] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-[70px] h-[3px] top-5 left-2.5 bg-[#bae7ff] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-5 left-[81px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[60px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[33px] h-[3px] top-[39px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-7 h-[3px] top-[39px] left-[83px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[34px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-12 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[99px] h-[3px] top-[55px] left-3 bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[25px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[43px] h-[3px] top-[39px] left-[39px] bg-[#fff1b8] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-11 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[65px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-4 h-1.5 top-1.5 left-24 bg-[#c81f1f]">
                              <div className="top-0 left-[3px] text-[4.6px] absolute [font-family:'Inter',Helvetica] font-semibold text-[#ffffff] tracking-[0] leading-[normal]">
                                PDF
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="absolute w-[120px] h-[74px] top-16 left-[413px]">
                          <div className="relative w-[118px] h-[74px] bg-[#ffffff] border-[0.12px] border-solid border-[#8c8c8c]">
                            <div className="absolute w-[65px] h-[3px] top-[30px] left-[46px] bg-[#f0f0f0] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-9 h-[3px] top-3 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-2 left-2.5 bg-[#d9d9d9] rounded-[0.49px]" />
                            <div className="absolute w-10 h-[3px] top-[30px] left-[5px] bg-[#d9f7be] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-[70px] h-[3px] top-5 left-2.5 bg-[#bae7ff] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-5 left-[81px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[60px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[33px] h-[3px] top-[39px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-7 h-[3px] top-[39px] left-[83px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[34px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-12 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[99px] h-[3px] top-[55px] left-3 bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[25px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[43px] h-[3px] top-[39px] left-[39px] bg-[#fff1b8] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-11 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[65px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-4 h-1.5 top-1.5 left-24 bg-[#c81f1f]">
                              <div className="top-0 left-[3px] text-[4.6px] absolute [font-family:'Inter',Helvetica] font-semibold text-[#ffffff] tracking-[0] leading-[normal]">
                                PDF
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="absolute w-[120px] h-[74px] top-[117px] left-[399px]">
                          <div className="relative w-[118px] h-[74px] bg-[#ffffff] border-[0.12px] border-solid border-[#8c8c8c]">
                            <div className="absolute w-[65px] h-[3px] top-[30px] left-[46px] bg-[#f0f0f0] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-9 h-[3px] top-3 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-2 left-2.5 bg-[#d9d9d9] rounded-[0.49px]" />
                            <div className="absolute w-10 h-[3px] top-[30px] left-[5px] bg-[#d9f7be] rounded-[0.49px] rotate-180" />
                            <div className="absolute w-[70px] h-[3px] top-5 left-2.5 bg-[#bae7ff] rounded-[0.49px]" />
                            <div className="absolute w-[31px] h-[3px] top-5 left-[81px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[60px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[33px] h-[3px] top-[39px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-7 h-[3px] top-[39px] left-[83px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[34px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-12 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[99px] h-[3px] top-[55px] left-3 bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[25px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[43px] h-[3px] top-[39px] left-[39px] bg-[#fff1b8] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-11 left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-[106px] h-[3px] top-[65px] left-[5px] bg-[#f0f0f0] rounded-[0.49px]" />
                            <div className="absolute w-4 h-1.5 top-1.5 left-24 bg-[#c81f1f]">
                              <div className="top-0 left-[3px] text-[4.6px] absolute [font-family:'Inter',Helvetica] font-semibold text-[#ffffff] tracking-[0] leading-[normal]">
                                PDF
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          className="absolute w-[38px] h-24 top-12 left-[365px]"
                          alt="Group"
                          src="/img/group-2201.png"
                        />
                      </div>
                      <div className="gap-6 pl-[39px] pr-0 py-0 flex items-center justify-center relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-thai-h1-semibold font-[number:var(--thai-h1-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h1-semibold-font-size)] tracking-[var(--thai-h1-semibold-letter-spacing)] leading-[var(--thai-h1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-h1-semibold-font-style)]">
                          04
                        </div>
                        <div className="relative flex-1 font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          สั่งให้กรอกข้อมูลลงฟอร์มหลายๆ ไฟล์
                          <br />
                          พร้อมกันได้ในทีเดียว
                        </div>
                      </div>
                    </div>
                    <img
                      className="top-[60px] left-[587px] absolute w-20 h-12"
                      alt="Arrow"
                      src="/img/arrow-1.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="absolute w-[215px] h-[67px] top-[1102px] left-[398px]">
                <div className="h-[67px]">
                  <div className="relative w-[215px] h-[67px]">
                    <div className="absolute w-[62px] h-[11px] top-0 left-[79px] border border-solid border-[#667fbf]" />
                    <div className="absolute w-[131px] h-[11px] top-[23px] left-[79px] border border-solid border-[#667fbf]" />
                    <div className="absolute w-[77px] h-2.5 top-10 left-[71px] border border-solid border-[#667fbf]" />
                    <div className="absolute w-[83px] h-2.5 top-[57px] left-[132px] border border-solid border-[#667fbf]" />
                    <img
                      className="absolute w-20 h-[31px] top-[3px] left-0"
                      alt="Group"
                      src="/img/group-2197.png"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {screenWidth < 1000 && (
            <>
              <footer className="flex flex-col w-full items-start gap-10 pt-12 pb-7 px-5 absolute top-[544px] left-0 bg-[#f7f9ff] opacity-90">
                <div className="flex flex-col items-start gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col items-start gap-9 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex flex-col items-start justify-end gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="relative w-[160.01px] h-10"
                        alt="Condoc logo"
                        src="/img/condoc-logo-1.png"
                      />
                      <div className="flex items-center justify-center gap-10 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative flex-1 mt-[-1.00px] font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-[#8c8c8c] text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                          ConDoc.ai Future of SMEs and Enterprises <br />
                          to Improve Efficiency.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="justify-center gap-6 flex-1 grow flex flex-col items-start relative">
                        <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                          Quick Links
                        </div>
                        <div className="justify-center gap-4 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                          <div className="relative w-fit mt-[-1.00px] font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-black text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] whitespace-nowrap [font-style:var(--thai-body-regular-font-style)]">
                            ประโยชน์
                          </div>
                          <div className="w-fit font-[number:var(--thai-body-regular-font-weight)] text-black text-[length:var(--thai-body-regular-font-size)] leading-[var(--thai-body-regular-line-height)] whitespace-nowrap relative font-thai-body-regular tracking-[var(--thai-body-regular-letter-spacing)] [font-style:var(--thai-body-regular-font-style)]">
                            คุณสมบัติเด่น
                          </div>
                          <div className="w-fit font-[number:var(--thai-body-regular-font-weight)] text-black text-[length:var(--thai-body-regular-font-size)] leading-[var(--thai-body-regular-line-height)] whitespace-nowrap relative font-thai-body-regular tracking-[var(--thai-body-regular-letter-spacing)] [font-style:var(--thai-body-regular-font-style)]">
                            วิธีการใช้งาน
                          </div>
                          <div className="w-fit font-[number:var(--thai-body-regular-font-weight)] text-black text-[length:var(--thai-body-regular-font-size)] leading-[var(--thai-body-regular-line-height)] whitespace-nowrap relative font-thai-body-regular tracking-[var(--thai-body-regular-letter-spacing)] [font-style:var(--thai-body-regular-font-style)]">
                            ตัวอย่างการใช้งานจริง
                          </div>
                          <div className="w-fit font-[number:var(--thai-body-regular-font-weight)] text-black text-[length:var(--thai-body-regular-font-size)] leading-[var(--thai-body-regular-line-height)] whitespace-nowrap relative font-thai-body-regular tracking-[var(--thai-body-regular-letter-spacing)] [font-style:var(--thai-body-regular-font-style)]">
                            ติดต่อเรา
                          </div>
                        </div>
                      </div>
                      <div className="gap-6 flex-1 self-stretch grow flex flex-col items-start relative">
                        <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                          Legal
                        </div>
                        <div className="flex flex-col items-start gap-4 relative flex-1 self-stretch w-full grow">
                          <div className="relative w-fit mt-[-1.00px] font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                            Privacy Policy
                          </div>
                          <div className="relative w-fit font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                            Terms of Service
                          </div>
                          <div className="relative w-fit font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                            Cookie Policy
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap-6 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                        Connect
                      </div>
                      <div className="flex flex-col items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="inline-flex items-start gap-3 relative flex-[0_0_auto]">
                          <img
                            className="relative w-5 h-5"
                            alt="Phone"
                            src="/img/phone.svg"
                          />
                          <div className="relative self-stretch w-[260px] mt-[-1.00px] font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] whitespace-nowrap [font-style:var(--eng-body-regular-font-style)]">
                            (+66) 92 424 0332
                          </div>
                        </div>
                        <div className="flex items-center self-stretch w-full gap-3 relative flex-[0_0_auto]">
                          <img
                            className="relative w-5 h-5"
                            alt="Email"
                            src="/img/email.svg"
                          />
                          <div className="mt-[-1.00px] font-eng-body-regular text-[length:var(--eng-body-regular-font-size)] leading-[var(--eng-body-regular-line-height)] relative flex-1 font-[number:var(--eng-body-regular-font-weight)] text-black tracking-[var(--eng-body-regular-letter-spacing)] [font-style:var(--eng-body-regular-font-style)]">
                            Contact@condoc.ai
                          </div>
                        </div>
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-115.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-10 pt-3 pb-0 px-0 relative self-stretch w-full flex-[0_0_auto] border-t [border-top-style:solid] border-[#bfbfbf]">
                  <p className="relative flex-1 mt-[-1.00px] font-eng-caption-regular font-[number:var(--eng-caption-regular-font-weight)] text-[#8c8c8c] text-[length:var(--eng-caption-regular-font-size)] text-center tracking-[var(--eng-caption-regular-letter-spacing)] leading-[var(--eng-caption-regular-line-height)] [font-style:var(--eng-caption-regular-font-style)]">
                    © 2024 ConDoc.ai All rights reserved.
                  </p>
                </div>
              </footer>
              <div className="flex flex-col w-full items-start justify-center gap-6 px-5 py-9 absolute top-0 left-0 bg-[#fcc536]">
                <div className="flex flex-col items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col items-start gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                      <p className="relative self-stretch mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-black text-[32px] tracking-[0] leading-[48px]">
                        <span className="font-[number:var(--thai-h3-semibold-font-weight)] font-thai-h3-semibold [font-style:var(--thai-h3-semibold-font-style)] tracking-[var(--thai-h3-semibold-letter-spacing)] leading-[var(--thai-h3-semibold-line-height)] text-[length:var(--thai-h3-semibold-font-size)]">
                          ลดเวลางานด้วย
                          <br />
                          ConDoc.ai{" "}
                        </span>
                        <span className="font-thai-h3-regular font-[number:var(--thai-h3-regular-font-weight)] text-black text-[length:var(--thai-h3-regular-font-size)] tracking-[var(--thai-h3-regular-letter-spacing)] leading-[var(--thai-h3-regular-line-height)] [font-style:var(--thai-h3-regular-font-style)]">
                          วันนี้!
                        </span>
                      </p>
                      <div className="self-stretch font-[number:var(--thai-h5-regular-font-weight)] text-[#434343] text-[length:var(--thai-h5-regular-font-size)] leading-[var(--thai-h5-regular-line-height)] relative font-thai-h5-regular tracking-[var(--thai-h5-regular-letter-spacing)] [font-style:var(--thai-h5-regular-font-style)]">
                        ติดต่อเราเพื่อรับข้อมูลเพิ่มเติม
                        <br />
                        และเริ่มต้นใช้งานได้ทันที
                      </div>
                    </div>
                    <a
                      className="inline-flex cursor-pointer items-center justify-center gap-2.5 pt-5 pb-4 px-8 relative flex-[0_0_auto] bg-black rounded-[100px]"
                      href={APP_URL}
                    >
                      <div className="w-fit mt-[-1.00px] font-[number:var(--thai-subtitle2-regular-font-weight)] text-[#fcc536] text-[length:var(--thai-subtitle2-regular-font-size)] leading-[var(--thai-subtitle2-regular-line-height)] whitespace-nowrap relative font-thai-subtitle2-regular tracking-[var(--thai-subtitle2-regular-letter-spacing)] [font-style:var(--thai-subtitle2-regular-font-style)]">
                        ทดลองใช้ฟรี
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center gap-6 px-8 py-9 relative self-stretch w-full flex-[0_0_auto] bg-[#ffffff] rounded-[30px]">
                  <div className="flex items-center gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-12 h-12"
                      alt="Frame"
                      src="/img/frame-48095590.svg"
                    />
                    <div className="relative flex-1 font-thai-h5-regular font-[number:var(--thai-h5-regular-font-weight)] text-black text-[length:var(--thai-h5-regular-font-size)] tracking-[var(--thai-h5-regular-letter-spacing)] leading-[var(--thai-h5-regular-line-height)] [font-style:var(--thai-h5-regular-font-style)]">
                      (+66) 92-424-0332
                    </div>
                  </div>
                  <div className="flex items-center gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-12 h-12"
                      alt="Frame"
                      src="/img/frame-48095590-1.svg"
                    />
                    <div className="font-thai-h5-regular text-[length:var(--thai-h5-regular-font-size)] leading-[var(--thai-h5-regular-line-height)] relative flex-1 font-[number:var(--thai-h5-regular-font-weight)] text-black tracking-[var(--thai-h5-regular-letter-spacing)] [font-style:var(--thai-h5-regular-font-style)]">
                      contact@condoc.ai
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {screenWidth >= 1000 && (
          <>
            <div className="flex flex-col w-[1440px] items-start gap-16 px-28 py-[60px] absolute top-[3571px] left-0">
              <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                <div className="w-[280px] top-[45px] left-[548px] absolute h-[19px] bg-[#fcc536]" />
                <div className="relative self-stretch mt-[-1.00px] font-thai-h2-medium font-[number:var(--thai-h2-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h2-medium-font-size)] text-center tracking-[var(--thai-h2-medium-letter-spacing)] leading-[var(--thai-h2-medium-line-height)] [font-style:var(--thai-h2-medium-font-style)]">
                  ตัวอย่างการใช้งานจริง
                </div>
              </div>
              <div className="flex flex-col items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex items-center justify-center gap-8 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex items-center gap-8 relative flex-1 grow">
                    <div className="flex flex-col items-start justify-center gap-6 p-10 relative flex-1 grow bg-[#fcc536] rounded-[30px]">
                      <div className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-635-5.svg"
                        />
                        <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                          <div className="relative flex-1 self-stretch mt-[-1.00px] font-thai-subtitle1-semibold font-[number:var(--thai-subtitle1-semibold-font-weight)] text-black text-[length:var(--thai-subtitle1-semibold-font-size)] tracking-[var(--thai-subtitle1-semibold-letter-spacing)] leading-[var(--thai-subtitle1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle1-semibold-font-style)]">
                            การประมวลผลใบแจ้งหนี้ / ใบเสร็จ
                          </div>
                          <div className="relative flex-1 self-stretch font-eng-subtitle2-regular font-[number:var(--eng-subtitle2-regular-font-weight)] text-black text-[length:var(--eng-subtitle2-regular-font-size)] tracking-[var(--eng-subtitle2-regular-letter-spacing)] leading-[var(--eng-subtitle2-regular-line-height)] [font-style:var(--eng-subtitle2-regular-font-style)]">
                            (Invoice / Receipt Processing)
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative self-stretch mt-[-1.00px] font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          แปลงเอกสารทุกรูปแบบให้กลายเป็น excel
                          อย่างรวดเร็วและแม่นยำ เพียงบอก
                          <br />
                          ข้อมูลที่ต้องการ เช่น วันที่ชำระเงิน, ร้านค้ารับเงิน,
                          ยอดเงิน และ VAT แยกจากกันได้
                        </p>
                      </div>
                    </div>
                    <div className="justify-center p-10 flex-1 grow bg-[#fff2d7] rounded-[30px] flex flex-col items-start gap-6 relative">
                      <div className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-635-6.svg"
                        />
                        <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                          <div className="relative flex-1 self-stretch mt-[-1.00px] font-thai-subtitle1-semibold font-[number:var(--thai-subtitle1-semibold-font-weight)] text-black text-[length:var(--thai-subtitle1-semibold-font-size)] tracking-[var(--thai-subtitle1-semibold-letter-spacing)] leading-[var(--thai-subtitle1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle1-semibold-font-style)]">
                            การจัดการใบกรมธรรม์
                          </div>
                          <div className="relative flex-1 self-stretch font-eng-subtitle2-regular font-[number:var(--eng-subtitle2-regular-font-weight)] text-black text-[length:var(--eng-subtitle2-regular-font-size)] tracking-[var(--eng-subtitle2-regular-letter-spacing)] leading-[var(--eng-subtitle2-regular-line-height)] [font-style:var(--eng-subtitle2-regular-font-style)]">
                            (Insurance Document)
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative self-stretch mt-[-1.00px] font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          อ่านข้อมูลในเอกสารกรมธรรม์ไม่ว่าจะมาจาก ประกันเจ้าไหน
                          ให้ออกมาเป็นข้อมูล
                          <br />
                          ลูกค้า หรือทรัพย์สินต่างๆ ให้มาอยู่ใน excel
                          ตารางเดียวกัน เพื่อนำไปใช้ต่อได้ทันที
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-8 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex items-center gap-8 relative flex-1 grow">
                    <div className="justify-center p-10 flex-1 grow bg-[#fff2d7] rounded-[30px] flex flex-col items-start gap-6 relative">
                      <div className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-636-3.svg"
                        />
                        <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                          <div className="relative flex-1 self-stretch mt-[-1.00px] font-thai-subtitle1-semibold font-[number:var(--thai-subtitle1-semibold-font-weight)] text-black text-[length:var(--thai-subtitle1-semibold-font-size)] tracking-[var(--thai-subtitle1-semibold-letter-spacing)] leading-[var(--thai-subtitle1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle1-semibold-font-style)]">
                            การวิเคราะห์สัญญา
                          </div>
                          <div className="relative flex-1 self-stretch font-eng-subtitle2-regular font-[number:var(--eng-subtitle2-regular-font-weight)] text-black text-[length:var(--eng-subtitle2-regular-font-size)] tracking-[var(--eng-subtitle2-regular-letter-spacing)] leading-[var(--eng-subtitle2-regular-line-height)] [font-style:var(--eng-subtitle2-regular-font-style)]">
                            (Contract Analysis)
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative self-stretch mt-[-1.00px] font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          สามารถอ่านสัญญา และดึงข้อมูลเฉพาะที่ต้องการได้เลย เช่น
                          วันที่, ชื่อลูกค้า, ที่อยู่, ราคา หรืออะไรที่ระบุ
                          ช่วยให้ตรวจสอบง่ายขึ้น ลดความเสี่ยงในการตรวจสอบ
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-center gap-6 p-10 relative flex-1 grow bg-[#fcc536] rounded-[30px]">
                      <div className="flex items-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-635-7.svg"
                        />
                        <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                          <div className="relative flex-1 self-stretch mt-[-1.00px] font-thai-subtitle1-semibold font-[number:var(--thai-subtitle1-semibold-font-weight)] text-black text-[length:var(--thai-subtitle1-semibold-font-size)] tracking-[var(--thai-subtitle1-semibold-letter-spacing)] leading-[var(--thai-subtitle1-semibold-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle1-semibold-font-style)]">
                            การประมวลผลเอกสารทางกฏหมาย
                          </div>
                          <div className="relative flex-1 self-stretch font-eng-subtitle2-regular font-[number:var(--eng-subtitle2-regular-font-weight)] text-black text-[length:var(--eng-subtitle2-regular-font-size)] tracking-[var(--eng-subtitle2-regular-letter-spacing)] leading-[var(--eng-subtitle2-regular-line-height)] [font-style:var(--eng-subtitle2-regular-font-style)]">
                            (Legal Document Processing)
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative self-stretch mt-[-1.00px] font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[#434343] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          ดึงข้อมูลเช่น ชื่อโจทก์, จำเลย, วันที่, เลขคดี
                          หรือแม้แต่ตัวเลขต่างๆ ที่เคยให้คนอ่าน
                          <br />
                          มากรอก และยังนำไปกรอกในฟอร์ม คำแถลง
                          หรือรายงานบันทึกต่างๆ ได้ทันที
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-[1216px] gap-12 p-[60px] absolute top-[543px] left-28 bg-[#fff2d7] rounded-[30px] flex-col items-start">
              <div className="w-[190px] top-[187px] left-[118px] absolute h-[19px] bg-[#fcc536]" />
              <div className="gap-[33px] flex items-center relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col w-[280px] items-start relative">
                  <div className="w-[248px] mt-[-1.00px] font-[number:var(--thai-h2-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h2-medium-font-size)] leading-[var(--thai-h2-medium-line-height)] relative font-thai-h2-medium tracking-[var(--thai-h2-medium-letter-spacing)] [font-style:var(--thai-h2-medium-font-style)]">
                    AI ที่ช่วยคุณ
                  </div>
                  <div className="relative w-fit font-thai-h5-medium font-[number:var(--thai-h5-medium-font-weight)] text-[#595959] text-[length:var(--thai-h5-medium-font-size)] tracking-[var(--thai-h5-medium-letter-spacing)] leading-[var(--thai-h5-medium-line-height)] whitespace-nowrap [font-style:var(--thai-h5-medium-font-style)]">
                    ลดภาระงานให้คน
                  </div>
                </div>
                <div className="flex flex-col items-start gap-8 relative flex-1 grow">
                  <div className="flex items-center gap-8 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex items-center gap-5 px-8 py-5 relative flex-1 grow bg-[#fcc536] rounded-3xl">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Time clock circle"
                        src="/img/time-clock-circle-streamline-ultimate-1.svg"
                      />
                      <div className="inline-flex pl-5 pr-0 py-0 flex-[0_0_auto] mr-[-3.50px] border-l [border-left-style:solid] border-[#434343] flex-col items-start relative">
                        <div className="text-neutral-800 relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          ทำงานได้เร็วขึ้น
                        </div>
                        <div className="text-[#434343] relative self-stretch opacity-80 font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          ไม่ต้องกรอกข้อมูลเอง ลดขั้นตอนที่
                          <br />
                          ยุ่งยากได้ถึง 90%
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-5 px-8 py-5 relative flex-1 grow bg-neutral-800 rounded-3xl">
                      <img
                        className="relative w-14 h-14"
                        alt="Check circle fill"
                        src="/img/check-circle-fill-streamline-bootstrap-1.svg"
                      />
                      <div className="inline-flex pl-5 pr-0 py-0 flex-[0_0_auto] border-l [border-left-style:solid] border-[#bfbfbf] flex-col items-start relative">
                        <div className="text-[#fcc536] relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          แม่นยำขึ้น
                        </div>
                        <div className="text-[#bfbfbf] relative self-stretch opacity-80 font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          AI ช่วยดึงข้อมูลจากเอกสาร
                          <br />
                          ลดข้อผิดพลาดได้
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-8 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex items-center gap-5 px-8 py-5 relative flex-1 grow bg-neutral-800 rounded-3xl">
                      <img
                        className="relative w-14 h-14"
                        alt="Money dollar circle"
                        src="/img/money-dollar-circle-fill-streamline-remix-fill-1.svg"
                      />
                      <div className="inline-flex pl-5 pr-0 py-0 flex-[0_0_auto] border-l [border-left-style:solid] border-[#bfbfbf] flex-col items-start relative">
                        <div className="text-[#fcc536] relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          ลดต้นทุน
                        </div>
                        <div className="text-[#bfbfbf] relative self-stretch opacity-80 font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          ใช้คนจำนวนเท่าเดิม แต่ทำงานได้
                          <br />
                          ปริมาณมากขึ้น
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-5 px-8 py-5 relative flex-1 grow bg-[#fcc536] rounded-3xl">
                      <img
                        className="relative w-14 h-[55.81px]"
                        alt="Capa"
                        src="/img/capa-1-1.svg"
                      />
                      <div className="inline-flex pl-5 pr-0 py-0 flex-[0_0_auto] mr-[-8.50px] border-l [border-left-style:solid] border-[#434343] flex-col items-start relative">
                        <div className="text-neutral-800 relative self-stretch mt-[-1.00px] font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                          หลุดพ้นงานรูทีน
                        </div>
                        <div className="text-[#434343] relative self-stretch opacity-80 font-thai-body-regular font-[number:var(--thai-body-regular-font-weight)] text-[length:var(--thai-body-regular-font-size)] tracking-[var(--thai-body-regular-letter-spacing)] leading-[var(--thai-body-regular-line-height)] [font-style:var(--thai-body-regular-font-style)]">
                          ปลดปล่อยให้ทีมงาน ได้ทำสิ่งที่มี
                          <br />
                          คุณค่าและพัฒนาศักยภาพของคนได้
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-[1440px] items-center gap-[231px] px-28 py-20 absolute top-[4309px] left-0 bg-[#f7f9ff] overflow-hidden">
              <div className="absolute w-[898px] h-[781px] top-[-5px] left-[779px] bg-[#fff2d7]" />
              <div className="inline-flex flex-col items-start gap-6 relative flex-[0_0_auto]">
                <div className="flex flex-col w-[393px] items-start pt-6 pb-0 px-0 relative flex-[0_0_auto]">
                  <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-h3-regular-font-weight)] text-black text-[length:var(--thai-h3-regular-font-size)] leading-[var(--thai-h3-regular-line-height)] relative font-thai-h3-regular tracking-[var(--thai-h3-regular-letter-spacing)] [font-style:var(--thai-h3-regular-font-style)]">
                    จัดการเอกสารด้วย AI
                  </div>
                  <p className="relative self-stretch font-thai-h2-semibold font-[number:var(--thai-h2-semibold-font-weight)] text-black text-[length:var(--thai-h2-semibold-font-size)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] [font-style:var(--thai-h2-semibold-font-style)]">
                    เริ่มต้น 2 บาท 
                    <br />
                    คุ้มค่ากว่า 10 เท่า
                    <br />
                    คนสบาย งานเสร็จไว
                  </p>
                </div>
                <img
                  className="relative w-[302px] h-[296px]"
                  alt="Tasks complete"
                  src="/img/tasks-complete-1-1.svg"
                />
              </div>
              <div className="w-[505px] flex flex-col items-start justify-center gap-10 relative self-stretch">
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[92px] h-[92px]"
                    alt="Correct"
                    src="/img/correct-1-2.svg"
                  />
                  <p className="relative w-[393px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-black text-[32px] tracking-[0] leading-[48px]">
                    <span className="font-thai-h4-regular font-[number:var(--thai-h4-regular-font-weight)] text-black text-[length:var(--thai-h4-regular-font-size)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)] [font-style:var(--thai-h4-regular-font-style)]">
                      เริ่มต้นเพียง
                      <br />
                    </span>
                    <span className="font-[number:var(--thai-h3-semibold-font-weight)] text-[length:var(--thai-h3-semibold-font-size)] font-thai-h3-semibold [font-style:var(--thai-h3-semibold-font-style)] tracking-[var(--thai-h3-semibold-letter-spacing)] leading-[var(--thai-h3-semibold-line-height)]">
                      2 บาทกว่าๆ ต่อเอกสาร
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[92px] h-[92px]"
                    alt="Correct"
                    src="/img/correct-2-2.svg"
                  />
                  <p className="relative w-[393px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-black text-4xl tracking-[0] leading-[48px]">
                    <span className="font-[number:var(--thai-h3-semibold-font-weight)] font-thai-h3-semibold [font-style:var(--thai-h3-semibold-font-style)] tracking-[var(--thai-h3-semibold-letter-spacing)] leading-[var(--thai-h3-semibold-line-height)] text-[length:var(--thai-h3-semibold-font-size)]">
                      ต้นทุนต่ำกว่า
                      <br />
                    </span>
                    <span className="text-[length:var(--thai-h4-regular-font-size)] font-thai-h4-regular [font-style:var(--thai-h4-regular-font-style)] font-[number:var(--thai-h4-regular-font-weight)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)]">
                      ใช้เวลาน้อยกว่า
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[92px] h-[92px]"
                    alt="Correct"
                    src="/img/correct-1-3.svg"
                  />
                  <p className="relative w-[393px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-black text-[32px] tracking-[0] leading-[48px]">
                    <span className="font-thai-h4-regular font-[number:var(--thai-h4-regular-font-weight)] text-black text-[length:var(--thai-h4-regular-font-size)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)] [font-style:var(--thai-h4-regular-font-style)]">
                      ใช้คนเท่าเดิม แต่...
                      <br />
                    </span>
                    <span className="font-[number:var(--thai-h3-semibold-font-weight)] text-[length:var(--thai-h3-semibold-font-size)] font-thai-h3-semibold [font-style:var(--thai-h3-semibold-font-style)] tracking-[var(--thai-h3-semibold-letter-spacing)] leading-[var(--thai-h3-semibold-line-height)]">
                      ได้ผลงานเพิ่มขึ้น
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[92px] h-[92px]"
                    alt="Correct"
                    src="/img/correct-2-3.svg"
                  />
                  <p className="relative w-[393px] mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-black text-[32px] tracking-[0] leading-[48px]">
                    <span className="font-thai-h4-regular font-[number:var(--thai-h4-regular-font-weight)] text-black text-[length:var(--thai-h4-regular-font-size)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)] [font-style:var(--thai-h4-regular-font-style)]">
                      จัดการ 100 ไฟล์
                      <br />
                    </span>
                    <span className="font-[number:var(--thai-h3-semibold-font-weight)] text-[length:var(--thai-h3-semibold-font-size)] font-thai-h3-semibold [font-style:var(--thai-h3-semibold-font-style)] tracking-[var(--thai-h3-semibold-letter-spacing)] leading-[var(--thai-h3-semibold-line-height)]">
                      เสร็จใน 5 นาที
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-[1440px] items-center gap-[251px] px-28 py-20 absolute top-[5085px] left-0 bg-[#fcc536]">
              <div className="inline-flex flex-col items-start gap-6 relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-6 relative flex-[0_0_auto]">
                  <div className="inline-flex relative flex-[0_0_auto] flex-col items-start">
                    <p className="mt-[-1.00px] font-normal text-black text-5xl leading-[72px] relative w-fit [font-family:'Noto_Sans_Thai',Helvetica] tracking-[0]">
                      <span className="font-[number:var(--thai-h2-semibold-font-weight)] font-thai-h2-semibold [font-style:var(--thai-h2-semibold-font-style)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] text-[length:var(--thai-h2-semibold-font-size)]">
                        ลดเวลางานด้วย
                        <br />
                        ConDoc.ai{" "}
                      </span>
                      <span className="font-thai-h2-regular font-[number:var(--thai-h2-regular-font-weight)] text-black text-[length:var(--thai-h2-regular-font-size)] tracking-[var(--thai-h2-regular-letter-spacing)] leading-[var(--thai-h2-regular-line-height)] [font-style:var(--thai-h2-regular-font-style)]">
                        วันนี้!
                      </span>
                    </p>
                    <div className="relative w-fit font-thai-h4-regular font-[number:var(--thai-h4-regular-font-weight)] text-[#434343] text-[length:var(--thai-h4-regular-font-size)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)] [font-style:var(--thai-h4-regular-font-style)]">
                      ติดต่อเราเพื่อรับข้อมูลเพิ่มเติม
                      <br />
                      และเริ่มต้นใช้งานได้ทันที
                    </div>
                  </div>
                  <a
                    className="bg-black inline-flex items-center justify-center gap-2.5 pt-5 pb-4 px-8 relative flex-[0_0_auto] rounded-[100px]"
                    href={APP_URL}
                  >
                    <div className="relative w-fit mt-[-1.00px] font-thai-subtitle2-regular font-[number:var(--thai-subtitle2-regular-font-weight)] text-[#fcc536] text-[length:var(--thai-subtitle2-regular-font-size)] tracking-[var(--thai-subtitle2-regular-letter-spacing)] leading-[var(--thai-subtitle2-regular-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle2-regular-font-style)]">
                      ทดลองใช้ฟรี
                    </div>
                  </a>
                </div>
              </div>
              <div className="pl-[92px] pr-12 py-12 flex-1 grow bg-[#ffffff] rounded-[30px] flex flex-col items-start justify-center gap-10 relative self-stretch">
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[72px] h-[72px]"
                    alt="Frame"
                    src="/img/frame-48095590-2.svg"
                  />
                  <div className="relative w-[393px] mr-[-35.00px] font-thai-h4-regular font-[number:var(--thai-h4-regular-font-weight)] text-black text-[length:var(--thai-h4-regular-font-size)] tracking-[var(--thai-h4-regular-letter-spacing)] leading-[var(--thai-h4-regular-line-height)] [font-style:var(--thai-h4-regular-font-style)]">
                    (+66) 92-424-0332
                  </div>
                </div>
                <div className="flex items-center gap-5 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[72px] h-[72px]"
                    alt="Frame"
                    src="/img/frame-48095590-3.svg"
                  />
                  <div className="w-[393px] mr-[-35.00px] font-thai-h4-regular text-[length:var(--thai-h4-regular-font-size)] leading-[var(--thai-h4-regular-line-height)] relative font-[number:var(--thai-h4-regular-font-weight)] text-black tracking-[var(--thai-h4-regular-letter-spacing)] [font-style:var(--thai-h4-regular-font-style)]">
                    contact@condoc.ai
                  </div>
                </div>
              </div>
            </div>
            <footer className="flex flex-col w-[1440px] items-start justify-end gap-10 pt-12 pb-7 px-28 absolute top-[5578px] left-0 bg-[#f7f9ff]">
              <div className="flex flex-col items-start gap-5 relative self-stretch w-full flex-[0_0_auto]">
                <div className="gap-6 flex-[0_0_auto] flex items-start relative self-stretch w-full">
                  <div className="flex flex-col items-start justify-end gap-4 relative flex-1 grow">
                    <img
                      className="relative w-[224.01px] h-14"
                      alt="Condoc logo"
                      src="/img/condoc-logo-1-3.png"
                    />
                    <div className="flex items-center justify-center gap-10 relative self-stretch w-full flex-[0_0_auto]">
                      <p className="relative flex-1 mt-[-1.00px] font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-[#8c8c8c] text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                        ConDoc.ai Future of SMEs and Enterprises <br />
                        to Improve Efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="inline-flex items-center gap-[72px] relative flex-[0_0_auto]">
                    <div className="inline-flex flex-col items-start justify-center gap-6 relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                        Quick Links
                      </div>
                      <div className="flex flex-col items-start justify-center gap-4 self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-black text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] whitespace-nowrap [font-style:var(--thai-body-medium-font-style)]">
                          ประโยชน์
                        </div>
                        <div className="relative w-fit font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-black text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] whitespace-nowrap [font-style:var(--thai-body-medium-font-style)]">
                          คุณสมบัติเด่น
                        </div>
                        <div className="relative w-fit font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-black text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] whitespace-nowrap [font-style:var(--thai-body-medium-font-style)]">
                          วิธีการใช้งาน
                        </div>
                        <div className="relative w-fit font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-black text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] whitespace-nowrap [font-style:var(--thai-body-medium-font-style)]">
                          ตัวอย่างการใช้งานจริง
                        </div>
                        <div className="relative w-fit font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-black text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] whitespace-nowrap [font-style:var(--thai-body-medium-font-style)]">
                          ติดต่อเรา
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex flex-col items-start gap-6 self-stretch relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                        Legal
                      </div>
                      <div className="flex flex-col items-start gap-4 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-1.00px] font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                          Privacy Policy
                        </div>
                        <div className="relative w-fit font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                          Terms of Service
                        </div>
                        <div className="relative w-fit font-eng-body-medium font-[number:var(--eng-body-medium-font-weight)] text-black text-[length:var(--eng-body-medium-font-size)] tracking-[var(--eng-body-medium-letter-spacing)] leading-[var(--eng-body-medium-line-height)] whitespace-nowrap [font-style:var(--eng-body-medium-font-style)]">
                          Cookie Policy
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex flex-col items-start gap-6 self-stretch relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] font-eng-subtitle2-semibold font-[number:var(--eng-subtitle2-semibold-font-weight)] text-black text-[length:var(--eng-subtitle2-semibold-font-size)] tracking-[var(--eng-subtitle2-semibold-letter-spacing)] leading-[var(--eng-subtitle2-semibold-line-height)] whitespace-nowrap [font-style:var(--eng-subtitle2-semibold-font-style)]">
                        Connect
                      </div>
                      <div className="flex flex-col items-start gap-4 relative flex-1 self-stretch w-full grow">
                        <div className="inline-flex items-start gap-3 relative flex-[0_0_auto]">
                          <img
                            className="relative w-5 h-5"
                            alt="Phone"
                            src="/img/phone-1.svg"
                          />
                          <div className="relative self-stretch w-[260px] mt-[-1.00px] font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] whitespace-nowrap [font-style:var(--eng-body-regular-font-style)]">
                            (+66) 92 424 0332
                          </div>
                        </div>
                        <div className="flex items-center self-stretch w-full gap-3 relative flex-[0_0_auto]">
                          <img
                            className="relative w-5 h-5"
                            alt="Email"
                            src="/img/email-1.svg"
                          />
                          <div className="flex-1 mt-[-1.00px] font-eng-body-regular text-[length:var(--eng-body-regular-font-size)] leading-[var(--eng-body-regular-line-height)] relative font-[number:var(--eng-body-regular-font-weight)] text-black tracking-[var(--eng-body-regular-letter-spacing)] [font-style:var(--eng-body-regular-font-style)]">
                            contact@condoc.ai
                          </div>
                        </div>
                        <img
                          className="relative flex-1 grow"
                          alt="Frame"
                          src="/img/frame-115-1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[54px] items-center gap-10 pt-3 pb-0 px-0 relative self-stretch w-full border-t [border-top-style:solid] border-[#bfbfbf]">
                <p className="relative flex-1 font-eng-caption-regular font-[number:var(--eng-caption-regular-font-weight)] text-[#8c8c8c] text-[length:var(--eng-caption-regular-font-size)] text-center tracking-[var(--eng-caption-regular-letter-spacing)] leading-[var(--eng-caption-regular-line-height)] [font-style:var(--eng-caption-regular-font-style)]">
                  © 2024 ConDoc.ai All rights reserved.
                </p>
              </div>
            </footer>
          </>
        )}

        {screenWidth < 1000 && (
          <>
            <div className="h-[3544px] top-[97px] absolute w-full left-0">
              <div className="flex flex-col w-full items-start gap-8 px-5 py-9 absolute top-[2007px] left-0 bg-[#f7f9ff]">
                <div className="flex flex-col items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[172.9px] h-[205px]"
                    alt="Table"
                    src="/img/table.svg"
                  />
                  <p className="relative self-stretch font-thai-h3-medium font-[number:var(--thai-h3-medium-font-weight)] text-black text-[length:var(--thai-h3-medium-font-size)] tracking-[var(--thai-h3-medium-letter-spacing)] leading-[var(--thai-h3-medium-line-height)] [font-style:var(--thai-h3-medium-font-style)]">
                    เปลี่ยนงานเอกสารที่ซ้ำซาก จำเจ ของคนให้เป็นเรื่องง่าย
                    <br />
                    เพียงไม่กี่คลิก ด้วย AI
                  </p>
                </div>
                <div className="flex flex-col items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col h-[332px] items-center justify-center gap-5 relative self-stretch w-full">
                    <img
                      className="w-[352px] h-[239px] ml-[-1.00px] mr-[-1.00px] relative object-cover"
                      alt="Image"
                      src="/img/image-1.png"
                    />
                    <div className="flex items-center justify-center gap-2.5 pl-12 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex-1 mt-[-1.00px] font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        ดึงเฉพาะข้อมูล ส่วนที่ต้องการ
                        <br />
                        ของเอกสารต่างๆ
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-[332px] items-center justify-center gap-5 relative self-stretch w-full">
                    <img
                      className="w-[274.85px] h-60 relative object-cover"
                      alt="Image"
                      src="/img/image-2-1.png"
                    />
                    <div className="flex items-center justify-center gap-2.5 pl-12 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex-1 mt-[-1.00px] font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        ให้มาอยู่เป็นระเบียบ ในรูปแบบ
                        <br />
                        ตาราง พร้อมกันได้ในทีเดียว
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-[332px] items-center justify-center gap-5 relative self-stretch w-full">
                    <img
                      className="w-[290.03px] h-60 relative object-cover"
                      alt="Image"
                      src="/img/image-3-1.png"
                    />
                    <div className="flex items-center justify-center gap-2.5 pl-12 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex-1 mt-[-1.00px] font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#434343] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        หรือนำมาใช้ต่อในอีเมล์, รายงาน,
                        <br />
                        ฟอร์ม, เทมเพลตต่างๆ ได้เลย
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full items-center justify-center gap-8 px-5 py-8 absolute top-0 left-0 bg-[#ffffff]">
                <div className="flex flex-col items-start justify-end gap-9 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col items-start justify-end gap-6 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="gap-2 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <img
                        className="relative w-[255px] h-[68.22px]"
                        alt="Condoc logo"
                        src="/img/condoc-logo-2.png"
                      />
                      <div className="self-stretch font-[number:var(--thai-subtitle1-medium-font-weight)] text-black text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        AI ที่ช่วยจัดการงานเอกสาร
                        <br />
                        ที่เคยใช้เวลาหลายวัน ให้เหลือไม่กี่นาที
                      </div>
                    </div>
                    <a
                      className="inline-flex cursor-pointer items-center justify-center gap-2.5 pt-4 pb-3 px-6 relative flex-[0_0_auto] bg-[#fcc536] rounded-[100px]"
                      href={APP_URL}
                    >
                      <div className="w-fit mt-[-1.00px] font-[number:var(--thai-subtitle2-regular-font-weight)] text-neutral-800 text-[length:var(--thai-subtitle2-regular-font-size)] leading-[var(--thai-subtitle2-regular-line-height)] whitespace-nowrap relative font-thai-subtitle2-regular tracking-[var(--thai-subtitle2-regular-letter-spacing)] [font-style:var(--thai-subtitle2-regular-font-style)]">
                        ทดลองใช้ฟรี
                      </div>
                    </a>
                  </div>
                  <img
                    className="relative w-[349.25px] h-[235px]"
                    alt="Meeting"
                    src="/img/meeting-1.svg"
                  />
                </div>
                <div className="flex flex-col w-[350px] items-start gap-12 px-6 py-9 relative flex-[0_0_auto] bg-[#fff2d7] rounded-[30px]">
                  <div className="absolute w-[103px] h-[19px] top-[66px] left-[87px] bg-[#fcc536]" />
                  <div className="flex flex-col items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex flex-col w-[280px] items-start relative flex-[0_0_auto]">
                      <div className="w-[248px] mt-[-1.00px] font-[number:var(--thai-h3-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h3-medium-font-size)] leading-[var(--thai-h3-medium-line-height)] relative font-thai-h3-medium tracking-[var(--thai-h3-medium-letter-spacing)] [font-style:var(--thai-h3-medium-font-style)]">
                        AI ที่ช่วยคุณ
                      </div>
                      <div className="w-fit font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#595959] text-[length:var(--thai-subtitle1-medium-font-size)] leading-[var(--thai-subtitle1-medium-line-height)] whitespace-nowrap relative font-thai-subtitle1-medium tracking-[var(--thai-subtitle1-medium-letter-spacing)] [font-style:var(--thai-subtitle1-medium-font-style)]">
                        ลดภาระงานให้คน
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex flex-col items-start justify-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="flex items-center gap-5 px-8 py-5 relative self-stretch w-full flex-[0_0_auto] bg-[#fcc536] rounded-3xl">
                          <img
                            className="relative flex-[0_0_auto]"
                            alt="Time clock circle"
                            src="/img/time-clock-circle-streamline-ultimate.svg"
                          />
                          <div className="flex flex-col items-start pl-5 pr-0 py-0 relative flex-1 grow border-l [border-left-style:solid] border-[#434343]">
                            <div className="w-fit whitespace-nowrap relative mt-[-1.00px] font-thai-subtitle2-medium font-[number:var(--thai-subtitle2-medium-font-weight)] text-neutral-800 text-[length:var(--thai-subtitle2-medium-font-size)] tracking-[var(--thai-subtitle2-medium-letter-spacing)] leading-[var(--thai-subtitle2-medium-line-height)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                              ทำงานได้เร็วขึ้น
                            </div>
                            <div className="relative self-stretch opacity-80 font-thai-caption-regular font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] tracking-[var(--thai-caption-regular-letter-spacing)] leading-[var(--thai-caption-regular-line-height)] [font-style:var(--thai-caption-regular-font-style)]">
                              ไม่ต้องกรอกข้อมูลเอง ลดขั้นตอนที่ยุ่งยาก
                              <br />
                              ได้ถึง 90%
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-5 px-8 py-5 relative self-stretch w-full flex-[0_0_auto] bg-neutral-800 rounded-3xl">
                          <img
                            className="relative w-14 h-14"
                            alt="Check circle fill"
                            src="/img/check-circle-fill-streamline-bootstrap.svg"
                          />
                          <div className="inline-flex pl-5 pr-0 py-0 flex-[0_0_auto] mr-[-10.00px] border-l [border-left-style:solid] border-[#bfbfbf] flex-col items-start relative">
                            <div className="relative self-stretch mt-[-1.00px] font-thai-subtitle2-medium font-[number:var(--thai-subtitle2-medium-font-weight)] text-[#fcc536] text-[length:var(--thai-subtitle2-medium-font-size)] tracking-[var(--thai-subtitle2-medium-letter-spacing)] leading-[var(--thai-subtitle2-medium-line-height)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                              แม่นยำขึ้น
                            </div>
                            <div className="relative self-stretch opacity-80 font-thai-caption-regular font-[number:var(--thai-caption-regular-font-weight)] text-[#bfbfbf] text-[length:var(--thai-caption-regular-font-size)] tracking-[var(--thai-caption-regular-letter-spacing)] leading-[var(--thai-caption-regular-line-height)] [font-style:var(--thai-caption-regular-font-style)]">
                              AI ช่วยดึงข้อมูลจากเอกสาร
                              <br />
                              ลดข้อผิดพลาดได้
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="flex items-center gap-5 px-8 py-5 relative self-stretch w-full flex-[0_0_auto] bg-neutral-800 rounded-3xl">
                          <img
                            className="relative w-14 h-14"
                            alt="Money dollar circle"
                            src="/img/money-dollar-circle-fill-streamline-remix-fill.svg"
                          />
                          <div className="flex pl-5 pr-0 py-0 flex-1 grow border-l [border-left-style:solid] border-[#bfbfbf] flex-col items-start relative">
                            <div className="relative self-stretch mt-[-1.00px] font-thai-subtitle2-medium font-[number:var(--thai-subtitle2-medium-font-weight)] text-[#fcc536] text-[length:var(--thai-subtitle2-medium-font-size)] tracking-[var(--thai-subtitle2-medium-letter-spacing)] leading-[var(--thai-subtitle2-medium-line-height)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                              ลดต้นทุน
                            </div>
                            <div className="relative self-stretch opacity-80 font-thai-caption-regular font-[number:var(--thai-caption-regular-font-weight)] text-[#bfbfbf] text-[length:var(--thai-caption-regular-font-size)] tracking-[var(--thai-caption-regular-letter-spacing)] leading-[var(--thai-caption-regular-line-height)] [font-style:var(--thai-caption-regular-font-style)]">
                              ใช้คนจำนวนเท่าเดิม แต่ทำงานได้ปริมาณมากขึ้น
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-5 px-8 py-5 relative self-stretch w-full flex-[0_0_auto] bg-[#fcc536] rounded-3xl">
                          <img
                            className="relative w-14 h-[55.81px]"
                            alt="Capa"
                            src="/img/capa-1.svg"
                          />
                          <div className="flex flex-col items-start pl-5 pr-0 py-0 relative flex-1 grow border-l [border-left-style:solid] border-[#434343]">
                            <div className="self-stretch relative mt-[-1.00px] font-thai-subtitle2-medium font-[number:var(--thai-subtitle2-medium-font-weight)] text-neutral-800 text-[length:var(--thai-subtitle2-medium-font-size)] tracking-[var(--thai-subtitle2-medium-letter-spacing)] leading-[var(--thai-subtitle2-medium-line-height)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                              หลุดพ้นงานรูทีน
                            </div>
                            <div className="relative self-stretch opacity-80 font-thai-caption-regular font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] tracking-[var(--thai-caption-regular-letter-spacing)] leading-[var(--thai-caption-regular-line-height)] [font-style:var(--thai-caption-regular-font-style)]">
                              ปลดปล่อยให้ทีมงานได้ทำ
                              <br />
                              สิ่งที่มีคุณค่า และพัฒนา
                              <br />
                              ศักยภาพของคนได้
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                    <div className="top-[30px] left-0 absolute w-[183px] h-[19px] bg-[#fcc536]" />
                    <div className="w-[248px] mt-[-1.00px] font-[number:var(--thai-h3-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h3-medium-font-size)] leading-[var(--thai-h3-medium-line-height)] relative font-thai-h3-medium tracking-[var(--thai-h3-medium-letter-spacing)] [font-style:var(--thai-h3-medium-font-style)]">
                      คุณสมบัติเด่น
                    </div>
                    <div className="relative w-fit font-thai-subtitle1-medium font-[number:var(--thai-subtitle1-medium-font-weight)] text-[#595959] text-[length:var(--thai-subtitle1-medium-font-size)] tracking-[var(--thai-subtitle1-medium-letter-spacing)] leading-[var(--thai-subtitle1-medium-line-height)] whitespace-nowrap [font-style:var(--thai-subtitle1-medium-font-style)]">
                      ของ ConDoc.ai
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-10 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="gap-6 flex-1 grow flex flex-col items-start relative">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-635.svg"
                        />
                        <div className="gap-1 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                          <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-medium-font-weight)] text-black text-[length:var(--thai-subtitle2-medium-font-size)] leading-[var(--thai-subtitle2-medium-line-height)] relative font-thai-subtitle2-medium tracking-[var(--thai-subtitle2-medium-letter-spacing)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                            ดึงข้อมูลด้วย 
                            <br />
                            AI อัจฉริยะ
                          </div>
                          <div className="relative self-stretch font-thai-caption-regular font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] tracking-[var(--thai-caption-regular-letter-spacing)] leading-[var(--thai-caption-regular-line-height)] [font-style:var(--thai-caption-regular-font-style)]">
                            เป็นมากกว่าแค่ OCR
                            <br />
                            แต่อ่าน และหาข้อมูล 
                            <br />
                            ที่ต้องการในเอกสาร
                          </div>
                        </div>
                      </div>
                      <div className="gap-6 flex-1 grow flex flex-col items-start relative">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-636.svg"
                        />
                        <div className="gap-1 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                          <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-medium-font-weight)] text-black text-[length:var(--thai-subtitle2-medium-font-size)] leading-[var(--thai-subtitle2-medium-line-height)] relative font-thai-subtitle2-medium tracking-[var(--thai-subtitle2-medium-letter-spacing)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                            ประมวลผลได้
                            <br />
                            อย่างรวดเร็ว
                          </div>
                          <div className="self-stretch font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                            จัดการเอกสารเสร็จ
                            <br />
                            ในไม่กี่วินาที ไม่ต้อง
                            <br />
                            เสียเวลาหลายชั่วโมง
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex flex-col items-start gap-6 relative flex-1 self-stretch grow">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-637.svg"
                        />
                        <div className="gap-1 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                          <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-medium-font-weight)] text-black text-[length:var(--thai-subtitle2-medium-font-size)] leading-[var(--thai-subtitle2-medium-line-height)] relative font-thai-subtitle2-medium tracking-[var(--thai-subtitle2-medium-letter-spacing)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                            รองรับการจัดการ
                            <br />
                            เอกสารจำนวนมาก
                          </div>
                          <div className="self-stretch font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                            AI ที่จัดการงานเอกสาร
                            <br />
                            กองโตด้วยตัวเองแล้ว
                            <br />
                            แจ้งคุณเมื่อทำงานเสร็จ
                          </div>
                        </div>
                      </div>
                      <div className="gap-6 flex-1 grow flex flex-col items-start relative">
                        <img
                          className="relative flex-[0_0_auto]"
                          alt="Frame"
                          src="/img/frame-637-1.svg"
                        />
                        <div className="gap-1 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                          <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-medium-font-weight)] text-black text-[length:var(--thai-subtitle2-medium-font-size)] leading-[var(--thai-subtitle2-medium-line-height)] relative font-thai-subtitle2-medium tracking-[var(--thai-subtitle2-medium-letter-spacing)] [font-style:var(--thai-subtitle2-medium-font-style)]">
                            รองรับเอกสาร
                            <br />
                            หลายประเภท
                          </div>
                          <div className="self-stretch font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                            รองรับเอกสารหลาย
                            <br />
                            ฟอร์แมท แม้เป็นงาน
                            <br />
                            ประเภทเดียวกัน
                            <br />
                            หลายรูปแบบ
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full items-start gap-8 px-5 py-9 absolute top-[3641px] left-0 bg-[#fff2d7]">
              <div className="flex flex-col items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start gap-6 relative self-stretch w-full flex-[0_0_auto]">
                  <p className="relative w-fit mt-[-1.00px] [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-transparent text-4xl tracking-[0] leading-9">
                    <span className="font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] leading-[var(--thai-h2-semibold-line-height)] font-thai-h2-semibold [font-style:var(--thai-h2-semibold-font-style)] tracking-[var(--thai-h2-semibold-letter-spacing)] text-[length:var(--thai-h2-semibold-font-size)]">
                      Extract
                      <br />
                    </span>
                    <span className="font-[number:var(--thai-h3-medium-font-weight)] text-black text-[length:var(--thai-h3-medium-font-size)] leading-[var(--thai-h3-medium-line-height)] font-thai-h3-medium [font-style:var(--thai-h3-medium-font-style)] tracking-[var(--thai-h3-medium-letter-spacing)]">
                      อ่าน - ดึง ข้อมูลจากเอกสาร
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-start gap-8 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col items-start gap-8 pt-0 pb-8 px-0 relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#8c8c8c]">
                    <div className="flex flex-col items-center justify-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="w-[350px] h-[171.67px] relative object-cover"
                        alt="Image"
                        src="/img/image-8.png"
                      />
                      <div className="flex items-center justify-center gap-2.5 pl-8 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-[42px] mt-[-1.00px] font-thai-h2-semibold font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h2-semibold-font-size)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] [font-style:var(--thai-h2-semibold-font-style)]">
                          01
                        </div>
                        <p className="flex-1 font-[number:var(--thai-body-medium-font-weight)] text-[#434343] text-[length:var(--thai-body-medium-font-size)] leading-[var(--thai-body-medium-line-height)] relative font-thai-body-medium tracking-[var(--thai-body-medium-letter-spacing)] [font-style:var(--thai-body-medium-font-style)]">
                          AI ที่สามารถอ่าน และเข้าใจว่า อยากได้
                          <br />
                          ข้อมูลอะไรมาใส่ใน Excel ให้เราได้
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="w-[350px] h-[164.87px] relative object-cover"
                        alt="Image"
                        src="/img/image-9.png"
                      />
                      <div className="flex items-center justify-center gap-2.5 pl-8 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                        <div className="relative w-[42px] mt-[-1.00px] font-thai-h2-semibold font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h2-semibold-font-size)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] [font-style:var(--thai-h2-semibold-font-style)]">
                          02
                        </div>
                        <p className="relative flex-1 font-thai-body-medium font-[number:var(--thai-body-medium-font-weight)] text-[#434343] text-[length:var(--thai-body-medium-font-size)] tracking-[var(--thai-body-medium-letter-spacing)] leading-[var(--thai-body-medium-line-height)] [font-style:var(--thai-body-medium-font-style)]">
                          โยนเป็น 10 ไฟล์ 100 ไฟล์ ให้ AI ทำให้
                          <br />
                          ไฟล์ละแถวได้เลย
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="relative self-stretch [font-family:'Noto_Sans_Thai',Helvetica] font-normal text-transparent text-4xl tracking-[0] leading-9">
                    <span className="font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] leading-[var(--thai-h2-semibold-line-height)] font-thai-h2-semibold [font-style:var(--thai-h2-semibold-font-style)] tracking-[var(--thai-h2-semibold-letter-spacing)] text-[length:var(--thai-h2-semibold-font-size)]">
                      Fill
                      <br />
                    </span>
                    <span className="font-[number:var(--thai-h3-medium-font-weight)] text-black text-[length:var(--thai-h3-medium-font-size)] leading-[var(--thai-h3-medium-line-height)] font-thai-h3-medium [font-style:var(--thai-h3-medium-font-style)] tracking-[var(--thai-h3-medium-letter-spacing)]">
                      รวม - กรอก ลงฟอร์มต่างๆ
                      <br />
                    </span>
                    <span className="text-[#434343] text-[length:var(--thai-subtitle1-regular-font-size)] font-thai-subtitle1-regular [font-style:var(--thai-subtitle1-regular-font-style)] font-[number:var(--thai-subtitle1-regular-font-weight)] tracking-[var(--thai-subtitle1-regular-letter-spacing)] leading-[var(--thai-subtitle1-regular-line-height)]">
                      สำหรับธุรกิจที่ต้องนำข้อมูลไปกรอกลงรายงาน หรือฟอร์มมากๆ
                    </span>
                  </p>
                  <div className="flex flex-col items-center justify-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="w-[350px] h-[126.53px] relative object-cover"
                      alt="Image"
                      src="/img/image-12.png"
                    />
                    <div className="flex items-center justify-center gap-2.5 pl-8 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="relative w-[42px] mt-[-1.00px] font-thai-h2-semibold font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h2-semibold-font-size)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] [font-style:var(--thai-h2-semibold-font-style)]">
                        03
                      </div>
                      <div className="flex-1 mt-[-1.00px] font-[number:var(--thai-body-medium-font-weight)] text-[#434343] text-[length:var(--thai-body-medium-font-size)] leading-[var(--thai-body-medium-line-height)] relative font-thai-body-medium tracking-[var(--thai-body-medium-letter-spacing)] [font-style:var(--thai-body-medium-font-style)]">
                        AI นำข้อมูลจากตาราง มากรอกลง
                        <br />
                        แบบฟอร์มที่ต้องการ (หรือมีข้อมูลเฉพาะ
                        <br />
                        เพิ่มเติมก็ได้)
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="w-[350px] h-[142.74px] relative object-cover"
                      alt="Image"
                      src="/img/image-10.png"
                    />
                    <div className="flex items-center justify-center gap-2.5 pl-8 pr-0 py-0 relative self-stretch w-full flex-[0_0_auto]">
                      <div className="relative w-[42px] mt-[-1.00px] font-thai-h2-semibold font-[number:var(--thai-h2-semibold-font-weight)] text-[#fcc536] text-[length:var(--thai-h2-semibold-font-size)] tracking-[var(--thai-h2-semibold-letter-spacing)] leading-[var(--thai-h2-semibold-line-height)] [font-style:var(--thai-h2-semibold-font-style)]">
                        04
                      </div>
                      <div className="flex-1 font-[number:var(--thai-body-medium-font-weight)] text-[#434343] text-[length:var(--thai-body-medium-font-size)] leading-[var(--thai-body-medium-line-height)] relative font-thai-body-medium tracking-[var(--thai-body-medium-letter-spacing)] [font-style:var(--thai-body-medium-font-style)]">
                        สั่งให้กรอกข้อมูลลงฟอร์มหลายๆ ไฟล์
                        <br />
                        พร้อมกันได้ในทีเดียว
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[2294px] top-[5220px] absolute w-full left-0">
              <div className="flex flex-col w-full items-start gap-6 px-5 py-9 absolute top-0 left-0">
                <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                  <div className="top-7 left-[104px] absolute w-[183px] h-[19px] bg-[#fcc536]" />
                  <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-h3-medium-font-weight)] text-neutral-800 text-[length:var(--thai-h3-medium-font-size)] leading-[var(--thai-h3-medium-line-height)] relative font-thai-h3-medium tracking-[var(--thai-h3-medium-letter-spacing)] [font-style:var(--thai-h3-medium-font-style)]">
                    ตัวอย่างการใช้งานจริง
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex flex-col h-[296.8px] items-start justify-center gap-6 p-8 relative self-stretch w-full bg-[#fcc536] rounded-[30px]">
                    <div className="flex items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-635-1.svg"
                      />
                      <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                        <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-semibold-font-weight)] text-black text-[length:var(--thai-subtitle2-semibold-font-size)] leading-[var(--thai-subtitle2-semibold-line-height)] relative font-thai-subtitle2-semibold tracking-[var(--thai-subtitle2-semibold-letter-spacing)] [font-style:var(--thai-subtitle2-semibold-font-style)]">
                          การประมวลผล
                          <br />
                          ใบแจ้งหนี้ / ใบเสร็จ
                        </div>
                        <div className="relative self-stretch font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                          (Invoice / Receipt Processing)
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <p className="self-stretch mt-[-1.00px] font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                        แปลงเอกสารทุกรูปแบบให้กลายเป็น excel อย่าง
                        รวดเร็วและแม่นยำ เพียงบอกข้อมูลที่ต้องการ เช่น
                        วันที่ชำระเงิน, ร้านค้ารับเงิน, ยอดเงิน และ VAT
                        แยกจากกันได้
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-6 p-8 relative self-stretch w-full flex-[0_0_auto] bg-[#fff2d7] rounded-[30px]">
                    <div className="flex items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-635-2.svg"
                      />
                      <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                        <div className="self-stretch font-[number:var(--thai-subtitle2-semibold-font-weight)] text-[length:var(--thai-subtitle2-semibold-font-size)] leading-[var(--thai-subtitle2-semibold-line-height)] relative font-thai-subtitle2-semibold text-black tracking-[var(--thai-subtitle2-semibold-letter-spacing)] [font-style:var(--thai-subtitle2-semibold-font-style)]">
                          การจัดการใบกรมธรรม์
                        </div>
                        <div className="relative self-stretch font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                          (Insurance Document)
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <p className="self-stretch mt-[-1.00px] font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                        อ่านข้อมูลในเอกสารกรมธรรม์ไม่ว่าจะมาจาก ประกันเจ้าไหน
                        ให้ออกมาเป็นข้อมูลลูกค้า หรือ
                        <br />
                        ทรัพย์สินต่างๆ ให้มาอยู่ใน excel ตารางเดียวกัน
                        เพื่อนำไปใช้ต่อได้ทันที
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-6 p-8 relative self-stretch w-full flex-[0_0_auto] bg-[#fff2d7] rounded-[30px]">
                    <div className="flex items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-636-1.svg"
                      />
                      <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                        <div className="self-stretch font-[number:var(--thai-subtitle2-semibold-font-weight)] text-[length:var(--thai-subtitle2-semibold-font-size)] leading-[var(--thai-subtitle2-semibold-line-height)] relative font-thai-subtitle2-semibold text-black tracking-[var(--thai-subtitle2-semibold-letter-spacing)] [font-style:var(--thai-subtitle2-semibold-font-style)]">
                          การวิเคราะห์สัญญา
                        </div>
                        <div className="relative self-stretch font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                          (Contract Analysis)
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <p className="self-stretch mt-[-1.00px] font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                        สามารถอ่านสัญญา และดึงข้อมูลเฉพาะที่ต้องการ
                        <br />
                        ได้เลย เช่น วันที่, ชื่อลูกค้า, ที่อยู่, ราคา หรืออะไร
                        <br />
                        ที่ระบุ ช่วยให้ตรวจสอบง่ายขึ้นลดความเสี่ยงในการ
                        <br />
                        ตรวจสอบ
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center gap-6 p-8 self-stretch w-full flex-[0_0_auto] bg-[#fcc536] rounded-[30px] flex-col items-start relative">
                    <div className="flex items-start gap-4 relative self-stretch w-full flex-[0_0_auto]">
                      <img
                        className="relative flex-[0_0_auto]"
                        alt="Frame"
                        src="/img/frame-635-3.svg"
                      />
                      <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow">
                        <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-subtitle2-semibold-font-weight)] text-black text-[length:var(--thai-subtitle2-semibold-font-size)] leading-[var(--thai-subtitle2-semibold-line-height)] relative font-thai-subtitle2-semibold tracking-[var(--thai-subtitle2-semibold-letter-spacing)] [font-style:var(--thai-subtitle2-semibold-font-style)]">
                          การประมวลผลเอกสารทางกฏหมาย
                        </div>
                        <div className="relative self-stretch font-eng-body-regular font-[number:var(--eng-body-regular-font-weight)] text-black text-[length:var(--eng-body-regular-font-size)] tracking-[var(--eng-body-regular-letter-spacing)] leading-[var(--eng-body-regular-line-height)] [font-style:var(--eng-body-regular-font-style)]">
                          (Legal Document Processing)
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
                      <p className="self-stretch mt-[-1.00px] font-[number:var(--thai-caption-regular-font-weight)] text-[#434343] text-[length:var(--thai-caption-regular-font-size)] leading-[var(--thai-caption-regular-line-height)] relative font-thai-caption-regular tracking-[var(--thai-caption-regular-letter-spacing)] [font-style:var(--thai-caption-regular-font-style)]">
                        ดึงข้อมูลเช่น ชื่อโจทก์, จำเลย, วันที่, เลขคดี หรือ
                        <br />
                        แม้แต่ตัวเลขต่างๆ ที่เคยให้คนอ่านมากรอก และยัง
                        <br />
                        นำไปกรอกในฟอร์ม คำแถลง หรือรายงานบันทึก
                        <br />
                        ต่างๆ ได้ทันที
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full items-start absolute top-[1250px] left-0">
                <div className="flex flex-col items-start gap-6 px-5 py-9 relative self-stretch w-full flex-[0_0_auto] bg-[#f7f9ff]">
                  <div className="flex flex-col items-start pt-6 pb-0 px-0 relative self-stretch w-full flex-[0_0_auto]">
                    <div className="self-stretch mt-[-1.00px] font-[number:var(--thai-h4-regular-font-weight)] text-black text-[length:var(--thai-h4-regular-font-size)] leading-[var(--thai-h4-regular-line-height)] relative font-thai-h4-regular tracking-[var(--thai-h4-regular-letter-spacing)] [font-style:var(--thai-h4-regular-font-style)]">
                      จัดการเอกสารด้วย AI
                    </div>
                    <p className="self-stretch font-[number:var(--thai-h3-semibold-font-weight)] text-black text-[length:var(--thai-h3-semibold-font-size)] leading-[var(--thai-h3-semibold-line-height)] relative font-thai-h3-semibold tracking-[var(--thai-h3-semibold-letter-spacing)] [font-style:var(--thai-h3-semibold-font-style)]">
                      เริ่มต้น 2 บาท 
                      <br />
                      คุ้มค่ากว่า 10 เท่า
                      <br />
                      คนสบาย งานเสร็จไว
                    </p>
                  </div>
                  <img
                    className="relative w-[215px] h-[210.73px]"
                    alt="Tasks complete"
                    src="/img/tasks-complete-1.svg"
                  />
                </div>
                <div className="flex-col items-start justify-center gap-7 px-5 py-9 bg-[#fff2d7] flex relative self-stretch w-full flex-[0_0_auto]">
                  <div className="flex items-center gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-[72px] h-[72px]"
                      alt="Correct"
                      src="/img/correct-1.svg"
                    />
                    <p className="flex-1 mt-[-1.00px] font-normal text-2xl leading-6 relative [font-family:'Noto_Sans_Thai',Helvetica] text-black tracking-[0]">
                      <span className="leading-[var(--thai-h5-regular-line-height)] font-thai-h5-regular [font-style:var(--thai-h5-regular-font-style)] font-[number:var(--thai-h5-regular-font-weight)] tracking-[var(--thai-h5-regular-letter-spacing)] text-[length:var(--thai-h5-regular-font-size)]">
                        เริ่มต้นเพียง
                        <br />
                      </span>
                      <span className="font-[number:var(--thai-h4-semibold-font-weight)] text-[length:var(--thai-h4-semibold-font-size)] leading-[var(--thai-h4-semibold-line-height)] font-thai-h4-semibold [font-style:var(--thai-h4-semibold-font-style)] tracking-[var(--thai-h4-semibold-letter-spacing)]">
                        2 บาทกว่าๆ ต่อเอกสาร
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-[72px] h-[72px]"
                      alt="Correct"
                      src="/img/correct-2.svg"
                    />
                    <p className="flex-1 mt-[-1.00px] font-normal text-black text-[28px] leading-7 relative [font-family:'Noto_Sans_Thai',Helvetica] tracking-[0]">
                      <span className="font-[number:var(--thai-h4-semibold-font-weight)] leading-[var(--thai-h4-semibold-line-height)] font-thai-h4-semibold [font-style:var(--thai-h4-semibold-font-style)] tracking-[var(--thai-h4-semibold-letter-spacing)] text-[length:var(--thai-h4-semibold-font-size)]">
                        ต้นทุนต่ำกว่า
                        <br />
                      </span>
                      <span className="text-[length:var(--thai-h5-regular-font-size)] leading-[var(--thai-h5-regular-line-height)] font-thai-h5-regular [font-style:var(--thai-h5-regular-font-style)] font-[number:var(--thai-h5-regular-font-weight)] tracking-[var(--thai-h5-regular-letter-spacing)]">
                        ใช้เวลาน้อยกว่า
                      </span>
                    </p>
                  </div>
                  <div className="items-center gap-3 flex relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-[72px] h-[72px]"
                      alt="Correct"
                      src="/img/correct-1-1.svg"
                    />
                    <p className="flex-1 mt-[-1.00px] font-normal text-2xl leading-6 relative [font-family:'Noto_Sans_Thai',Helvetica] text-black tracking-[0]">
                      <span className="leading-[var(--thai-h5-regular-line-height)] font-thai-h5-regular [font-style:var(--thai-h5-regular-font-style)] font-[number:var(--thai-h5-regular-font-weight)] tracking-[var(--thai-h5-regular-letter-spacing)] text-[length:var(--thai-h5-regular-font-size)]">
                        ใช้คนเท่าเดิม แต่...
                        <br />
                      </span>
                      <span className="font-[number:var(--thai-h4-semibold-font-weight)] text-[length:var(--thai-h4-semibold-font-size)] leading-[var(--thai-h4-semibold-line-height)] font-thai-h4-semibold [font-style:var(--thai-h4-semibold-font-style)] tracking-[var(--thai-h4-semibold-letter-spacing)]">
                        ได้ผลงานเพิ่มขึ้น
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center gap-3 relative self-stretch w-full flex-[0_0_auto]">
                    <img
                      className="relative w-[72px] h-[72px]"
                      alt="Correct"
                      src="/img/correct-2-1.svg"
                    />
                    <p className="flex-1 mt-[-1.00px] font-normal text-2xl leading-6 relative [font-family:'Noto_Sans_Thai',Helvetica] text-black tracking-[0]">
                      <span className="leading-[var(--thai-h5-regular-line-height)] font-thai-h5-regular [font-style:var(--thai-h5-regular-font-style)] font-[number:var(--thai-h5-regular-font-weight)] tracking-[var(--thai-h5-regular-letter-spacing)] text-[length:var(--thai-h5-regular-font-size)]">
                        จัดการ 100 ไฟล์
                        <br />
                      </span>
                      <span className="font-[number:var(--thai-h4-semibold-font-weight)] text-[length:var(--thai-h4-semibold-font-size)] leading-[var(--thai-h4-semibold-line-height)] font-thai-h4-semibold [font-style:var(--thai-h4-semibold-font-style)] tracking-[var(--thai-h4-semibold-letter-spacing)]">
                        เสร็จใน 5 นาที
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center p-5 fixed top-[25px] left-0 bg-[#fcc536]">
              <div className="flex flex-col items-start gap-[6.85px] relative flex-1 grow">
                <img
                  className="relative w-[128.01px] h-8"
                  alt="Condoc logo"
                  src="/img/condoc-logo-1-1.png"
                />
              </div>
            </div>
            <div className="fixed w-full h-[25px] top-0 left-0 bg-[#fcc536]"></div>
          </>
        )}
      </div>
    </div>
  );
};
